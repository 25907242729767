import React from 'react';
import { Container, Row, Col } from "react-bootstrap"
import './TeamDetails.scss'
import GetGGFXImage from '../common/site/get-ggfx-image';
import parse from 'html-react-parser'
import { Link } from 'gatsby';
function TeamDetails({ team }) {
    let processedImages = JSON.stringify({});
    if (team?.imagetransforms?.Staff_Image_Transforms) {
        processedImages = team.imagetransforms.Staff_Image_Transforms;
    }
    return (
        <Container className='team-detail-container'>
            <div className='team-inner-wrap'>
                <Row>
                    <Col lg={4}>
                        <div className='team-detail-img'>
                            <GetGGFXImage imagename={"teams.Staff_Image.detailimg"} imagesource={team.Staff_Image} fallbackalt={team.Name} imagetransformresult={processedImages} id={team.id} />
                        </div>
                    </Col>
                    <Col lg={7} className="team-details-enclose">
                        <h1>{team.Name}<em>{team.Sub_Name}</em></h1>
                        <span>{team.Designation}</span>
                        <Link to="/contact-us/" className='btn btn-primary-outline'
                        state={{ 
                        teamid: team.id,
                        teamemail: team.Email,
                        staffname : team.Name,
                        emailsubject: "Team Contact"
                        }}
                        >Email Directly</Link>
                        <span className='team-ct'>
                            <a href={`tel:${team.Phone}`}><i className='icon-phone'></i>{team.Phone}</a>
                        </span>
                        {team.Bio && <div className='team-bio'>
                            {parse(team.Bio)}
                        </div>}
                    </Col>
                </Row>
            </div>

        </Container>
    );
}

export default TeamDetails;
