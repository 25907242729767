import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import {Button, Form, Container, Row, Col,Dropdown} from 'react-bootstrap';
// import { BackParentMenu } from "../../../queries/common_use_query";
import { useStaticQuery, graphql } from "gatsby"

// markup
const SiteBreadcrumbs = (props ) => {
    const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        allMenus(sort:"Sorting:asc") {
          id
          Label
          Primary_URL
          Secondary_URL
          Show_In_Top_Nav
          Main_Parent {
            id
            Label
            Primary_URL
            Secondary_URL
          }
          Sub_Parent {
            id
            Label
            Primary_URL
            Secondary_URL
          }
        }
      }
    }
  `)
    var menu_data = data.glstrapi.allMenus;
    if (typeof window !== "undefined") {
        var pageURL = window.location.href.split("#")[0] ? window.location.href.split("#")[0] : '';
        var removeSlash = pageURL.replace(/^(.+?)\/*?$/, "$1");
        var currenturl = props.alias?props.alias:removeSlash.substr(removeSlash.lastIndexOf('/') + 1);
        currenturl = currenturl.replace(/\?.+/, '')
        if(currenturl === "send-to-friend" || currenturl === "book-a-viewing") {
          currenturl=""
        }
    }    
    //const {loading, error, data} = BackParentMenu(currenturl);
    return ( 
        <Container className="breadcrumbs-container">
           <div className={`back-block breadcrumbs ${props.style ? props.style : ''}`}>
               
                           <div className="back-wrap">
                           <nav aria-label="breadcrumb">
                           <ol class="breadcrumb">
                           <li class="breadcrumb-item"><Link to="/" className="back-btn">Home</Link></li>
                                {menu_data && menu_data.length > 0 && menu_data.map((menu, index) => {
                                return<>        
                                {menu.Main_Parent === null && menu.Sub_Parent === null && menu.Primary_URL === currenturl && 
                                <li class="breadcrumb-item"><Link  to={`/${menu.Primary_URL}/`} className="back-btn">{menu.Label}</Link></li>
                                }                        
                                {menu.Main_Parent && menu.Sub_Parent === null && menu.Primary_URL === currenturl &&
                                <li class="breadcrumb-item"><Link to={`/${menu.Main_Parent.Primary_URL}/`} className="back-btn 1">{menu.Main_Parent.Label}</Link></li>
                                }
                                {/* {menu.Main_Parent && menu.Sub_Parent === null && menu.Primary_URL === currenturl && menu.Main_Parent.Secondary_URL == '#' && 
                                <li class="breadcrumb-item"><a href="javascript:;" className="back-btn 2">{menu.Main_Parent.Label}</a></li>
                                } */}
                                {/* {menu.Main_Parent && menu.Sub_Parent === null && menu.Primary_URL === currenturl && 
                                <li class="breadcrumb-item"><Link to={`${menu.Main_Parent.Secondary_URL}`} className="back-btn 3">{menu.Main_Parent.Label}</Link></li>
                                } */}
                                {menu.Main_Parent && menu.Sub_Parent && menu.Primary_URL === currenturl &&
                                <li class="breadcrumb-item"><Link to={`/${menu.Main_Parent.Primary_URL}/${menu.Sub_Parent.Primary_URL}/`} className="back-btn 4">{menu.Sub_Parent.Label}</Link></li>
                                }  
                                {menu.Main_Parent !=null && !props.itemlabel && !props.menulabel && menu.Primary_URL === currenturl &&
                                <li class="breadcrumb-item"><Link className="back-btn 5">{menu.Label}</Link></li>                           
                                }
                                {menu.Main_Parent !=null && !props.itemlabel && props.menulabel && menu.Primary_URL === currenturl &&
                                <li class="breadcrumb-item"><Link to={`/${menu.Main_Parent.Primary_URL}/${menu.Primary_URL}/`} className="back-btn 6">{menu.Label} <i className="icon-arrow-right"></i></Link></li>
                                }
                                </>
                                })}
                                {props.baselabel && props.baseurl &&
                                <li class="breadcrumb-item"><Link to={props.baseurl} className="back-btn">{props.baselabel}</Link></li>
                                }
                                {props.itemlabel &&
                                <li class="breadcrumb-item"><Link to={props.property_url?props.property_url:""} className="back-btn">{props.itemlabel}</Link></li>
                                }
                                {props.menulabel &&
                                <li class="breadcrumb-item"><Link className="back-btn">{props.menulabel}</Link></li>
                                }
                                </ol>
                                </nav>
                           </div>
                      
           </div>
        </Container>
    )
}
export default SiteBreadcrumbs
