import { graphql } from "gatsby"
import React from "react"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import SiteBreadcrumbs from "../components/Breadcrumb/site-breadcrumbs"
import TeamDetails from "../components/TeamDetails/TeamDetails"
import SEO from "../components/Seo/seo"

const TeamDetailPage = ({data}) => {
  const team = data.glstrapi.team
  return (
    <div className="team-detail-wrapper">
    <SEO
      title={team.Meta_Title ? team.Meta_Title : team.Name +' | '+team.Designation}
      description={team.Meta_Description ? team.Meta_Description : `Get to know about ${team.Name} here. Contact one of estate agents for assistance in finding the right properties for you.`}
    />
    <Header />
    <SiteBreadcrumbs style="landing-breadcrumbs" alias="meet-the-team" menulabel={team.Name}/>
    <TeamDetails team={team}/>
    <Footer/>
    </div>
  )
}

export default TeamDetailPage

export const pageQuery = graphql`
  query GetTeamDetails($articleId: ID!) {
    glstrapi {
        team(id: $articleId, publicationState: LIVE) {
          id
          Name
          Meta_Title
          Meta_Description
          Sub_Name
          URL
          Designation
          Bio
          Phone
          Email
          Staff_Image {
            url
            alternativeText
          }
        }
      }
    }`